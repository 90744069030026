import React from 'react';
import ReactDOM from 'react-dom/client';
import axios from 'axios';
import reportWebVitals from './reportWebVitals';
import { UserProvider } from './context/User.context';

import { AlertProvider, ConfirmProvider } from '@moreirapontocom/npmhelpers';
import { DataProvider } from './context/Data.context';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { initializeApp } from 'firebase/app';

import App from './views/App/App';
import Schemas from './views/Schemas/Schemas.view';
import Backups from './views/Backups/Backups.view';
import Dashboard from './views/Dashboard/Dashboard.view';
import Login from './views/Login/Login.view';
import Terms from './views/Terms/Terms.view';
import Subscription from './views/Subscription/Subscription.view';
import Signup from './views/Signup/Signup.view';
import PasswordRecover from './views/PasswordRecover/PasswordRecover.view';
import Faq from './views/Faq/Faq.view';

import './index.scss';

initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_API_ID,
});

axios.defaults.baseURL = process.env.REACT_APP_AXIOS_URL || "";

axios.interceptors.request.use(function (config) {
  const token: any = localStorage.getItem('access_token');
  if (!token) return config;
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

const routes: any = createBrowserRouter([
  {
    path: '/',
    element: <Login />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/signup',
    element: <Signup />,
  },
  {
    path: '/recoverPassword',
    element: <PasswordRecover />,
  },
  {
    path: 'terms',
    element: <Terms />,
  },
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '/panel',
        element: <Dashboard />,
      },
      {
        path: '/schemas',
        element: <Schemas />,
      },
      {
        path: '/backups',
        element: <Backups />,
      },
      {
        path: '/subscription',
        element: <Subscription />,
      },
      {
        path: '/faq',
        element: <Faq />,
      },
    ],
  }
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <AlertProvider>
      <UserProvider>
        <ConfirmProvider>
          <DataProvider>
            <RouterProvider router={routes} />
          </DataProvider>
        </ConfirmProvider>
      </UserProvider>
    </AlertProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
