import { useContext, useEffect, useState } from "react";
import {
    getBackupFileUrl,
    getBackups,
    retryBackup,
    updateBackupNotes,
} from "../../services/backup.service";
import {
    convertFirebaseTimestampToString,
    formatBytes,
    i18n,
} from "../../helpers";
import Expander from "../../components/Expander/Expander.component";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import TooltipComponent from "../../components/Tooltip/Tooltip.component";
import { AlertContext, Separator, Loading } from "@moreirapontocom/npmhelpers";
import { DataContext } from "../../context/Data.context";
import { Link } from "react-router-dom";

const Backups = () => {
    useEffect(() => {
        _getBackups();
    }, []);

    const {data} = useContext(DataContext);
    const {setAlert} = useContext(AlertContext);
    const [backups, setBackups] = useState([]);
    const [backupsImmutable, setBackupsImmutable] = useState([]); // For filtering
    const [loadingBackups, setLoadingBackups] = useState(true);
    const [loadingDownloadFile, setLoadingDownloadFile] = useState(false);
    const [loadingDownloadFileId, setLoadingDownloadFileId] = useState(null);

    const _getBackups = async () => {
        setLoadingBackups(true);
        await getBackups().then((response: any) => {
            if (response.message === "OK") {
                setBackups(response.backups);
                setBackupsImmutable(response.backups);
                populateSchemasList();
                setLoadingBackups(false);
                return;
            }
            console.log('Error loading backups. Message received', response);
            setAlert({type: 'danger', message: 'Erro ao carregar os backups'});
            setLoadingBackups(false);
        }).catch((error: any) => {
            console.log('Error loading backups', error);
            setAlert({type: 'danger', message: 'Erro ao carregar os backups (erroNo: 2)'});
            setLoadingBackups(false);
        });
    };

    const _getFileUrl = async (backup: any) => {
        setLoadingDownloadFile(true);
        setLoadingDownloadFileId(backup.id);
        await getBackupFileUrl(backup.id).then((response: any) => {
            if (response.downloadUrl.name === "SigningError") {
                setLoadingDownloadFile(false);
                setLoadingDownloadFileId(null);
                setAlert({ type: "danger", message: "Erro ao assinar o arquivo de download" });
                return;
            }

            setAlert({ type: "success", message: "Download em progresso" });
            const link: any = document.createElement('a');
            link.href = response.downloadUrl;
            link.download = backup.fileName || "backup.sql";
            document.body.appendChild(link);
            link.click();
            link.remove();

            setLoadingDownloadFile(false);
            setLoadingDownloadFileId(null);
        }).catch((error: any) => {
            console.log("ERROR", error);
            setLoadingDownloadFile(false);
            setLoadingDownloadFileId(null);
            setAlert({ type: "danger", message: "Erro ao buscar o arquivo" });
        });
    };

    const [loadingTryBackupAgain, setLoadingTryBackupAgain] = useState(false);

    const _retryBackup = async (backup: any) => {
        setLoadingTryBackupAgain(true);
        await retryBackup({ backupId: backup.id }).then((response: any) => {
            if (response.message === "OK") {
                setAlert({type: 'success', message: `Reiniciando o backup "${backup.schemaDescription}"`});
                setLoadingTryBackupAgain(false);
                return;
            }
            console.log('Error running manual backup. Message received', response);
            setAlert({type: 'danger', message: 'Erro ao reiniciar o backup'});
            setLoadingTryBackupAgain(false);
        }).catch((error: any) => {
            console.log('Error running manual backup', error);
            setAlert({type: 'danger', message: 'Erro ao reiniciar o backup manualmente'});
            setLoadingTryBackupAgain(false);
        });
    };

    const [editBackupNoteId, setEditBackupNoteId] = useState(null);

    const _saveBackupNote = async (e: any) => {
        const backup: any = backups.find((backup: any) => backup.id === editBackupNoteId);
        if (!backup) {
            return;
        }

        const note: string = e.target.value || "";
        backup.notes = note;
        setBackups([...backups]);

        await updateBackupNotes({ backupId: backup.id, notes: note }).then((response: any) => {
            setAlert({ type: "success", message: "Anotação salva" });
        }).catch((error: any) => {
            console.log("ERROR", error);
            setAlert({ type: "danger", message: "Erro ao salvar a anotação" });
        });
    };

    const populateSchemasList = () => {
        const schemas: any = [];
        backupsImmutable.forEach((backup: any) => {
            if (!schemas.includes(backup.schemaDescription)) {
                schemas.push(backup.schemaDescription);
            }
        });
        return schemas;
    };

    const filterBySchema = (schema: string) => {
        if (!schema) {
            setBackups([...backupsImmutable]);
            return;
        }

        const filteredBackups: any = backupsImmutable.filter((backup: any) => {
            return backup.schemaDescription === schema;
        });
        setBackups(filteredBackups);
    };

    return (
        <div>
            <PageHeader>
                <h1>Backups</h1>
                <p>
                    Todos os seus backups disponíveis estão listados abaixo.
                </p>
            </PageHeader>

            <Loading loading={loadingBackups} />

            <div className="container">
                {!loadingBackups && backups.length === 0 && <>
                    <Separator size={30} />

                    <div className="text-center">
                        <p>
                            <strong>Nenhum backup encontrado</strong>
                        </p>
                        <p>
                            Você pode <Link to="/schemas" className="link-primary inline">executar um backup manualmente</Link> ou<br />aguardar os agendamentos automáticos.
                        </p>
                    </div>
                </>}

                {!loadingBackups && backups.length > 0 && <>
                    <div data-testid="backups">
                        <div className="row">
                            <div className="col-4">
                                <small className="text-muted">{backups.length} backups</small>
                            </div>
                            <div className="col-4 text-center">
                                <div className="form-group">
                                    <select
                                        className="form-control border-0"
                                        onChange={(e: any) => filterBySchema(e.target.value)}>
                                        <option value="">Todos os Schemas</option>
                                        {populateSchemasList().map((schema: string) => (
                                            <option key={`schema-${schema}`} value={schema}>{schema}</option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className="col-4 text-end">
                                <button
                                    disabled={loadingBackups}
                                    onClick={() => _getBackups()}
                                    className="btn btn-link btn-sm inline decoration-none">
                                        <Loading loading={loadingBackups} parent="inline" color="text-primary" />
                                        {!loadingBackups && <i className="fas fa-sync me-2"></i>} Atualizar
                                </button>
                            </div>
                        </div>

                        <Separator size={10} />

                        <div data-testid="backups-list">
                            {backups.map((backup: any) => (
                                <div key={`backup-${backup.id}`} className="data-testid-backup-item">
                                    <div className="card border-0 shadow-sm">
                                        <div className="card-body">

                                            <div className="float-end">
                                                {backup.status === "in_progress" && <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary"
                                                        disabled>
                                                            <i className="fas fa-clock me-2"></i> Em Progresso
                                                    </button>
                                                </>}

                                                {backup.status === "error" && <>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger"
                                                        disabled>
                                                            <i className="fas fa-exclamation-triangle me-2"></i> Falhou
                                                    </button>
                                                </>}

                                                {backup.status === "done" && <>
                                                    {data.subscriptionPlan === "free" && <>
                                                        <Link
                                                            to="/subscription"
                                                            className="btn btn-outline-primary">
                                                                <i className="fas fa-file-download me-2"></i> Download <div className="badge bg-primary ms-2">pro</div>
                                                        </Link>
                                                    </>}

                                                    {data.subscriptionPlan === "pro" && <>
                                                        {backup.usingDropbox === true && backup.dropboxUploadStatus === "success" && <>
                                                            <a
                                                                href="https://www.dropbox.com/home"
                                                                target="_blank"
                                                                rel="noreferrer"
                                                                className="btn btn-outline-primary">
                                                                    <i className="fab fa-dropbox me-2"></i> Ver em Dropbox.com
                                                            </a>
                                                        </>}

                                                        <button
                                                            type="button"
                                                            className="btn btn-primary ms-2"
                                                            disabled={!backup.integrity || (loadingDownloadFile && loadingDownloadFileId === backup.id)}
                                                            onClick={() => _getFileUrl(backup)}>
                                                                <Loading loading={loadingDownloadFile && loadingDownloadFileId === backup.id} parent="inline" color="text-white" />
                                                                {(loadingDownloadFileId !== backup.id) && <><i className="fas fa-file-download me-2"></i></>} Download
                                                        </button>
                                                    </>}
                                                </>}
                                            </div>

                                            <div className="mb-1 data-testid-backup-item-description">
                                                <strong>Schema:</strong> {backup.schemaDescription}
                                            </div>

                                            <small className="text-muted">
                                                {backup.trigger && backup.trigger === "scheduled" && <>
                                                    <i className="fas fa-clock me-2"></i> Backup Criado Automaticamente
                                                    <Separator size={10} />
                                                </>}

                                                {backup.usingDropbox === true && <>
                                                    {backup.dropboxUploadStatus === "success" && <>
                                                        <i className="fab fa-dropbox me-2"></i> Backup Enviado para o Dropbox
                                                    </>}
                                                    {backup.dropboxUploadStatus === "failed" && <>
                                                        <span className="text-danger">
                                                            <i className="fab fa-dropbox me-2 text-danger"></i> Erro ao Enviar o Backup para o Dropbox
                                                        </span>
                                                    </>}
                                                    <Separator size={10} />
                                                </>}

                                                Arquivo: {backup.fileName} | 
                                                Iniciado Em: {convertFirebaseTimestampToString(backup.createdAt, i18n().dateFormat)} | 
                                                Tamanho: {backup.size ? formatBytes(backup.size) : "-"}

                                                <Separator size={10} />

                                                {editBackupNoteId !== backup.id && <>
                                                    <div onClick={() => setEditBackupNoteId(backup.id)}>
                                                        {backup.notes || "Clique para adicionar uma nota"} <i className="fas fa-pen ms-3 cursor-pointer"></i>
                                                    </div>
                                                </>}

                                                {editBackupNoteId === backup.id && <>
                                                    <input
                                                        type="text"
                                                        onBlur={() => setEditBackupNoteId(null)}
                                                        onKeyDown={(e) => {
                                                            if (e.key === "Enter") {
                                                                _saveBackupNote(e);
                                                                setEditBackupNoteId(null);
                                                            }
                                                        }}
                                                        defaultValue={backup.notes || ""}
                                                        onChange={(e) => {}}
                                                        maxLength={100}
                                                        placeholder="Escreva uma nota sobre este backup e aperte ENTER para salvar"
                                                        className="form-control form-control-sm" />
                                                </>}
                                            </small>

                                            <Expander contentId={backup.id} labelExpand="Mostrar mais" labelCollapse="Mostrar menos">
                                                <table className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>Banco de Dados</th>
                                                            <th>Atualizado Em</th>
                                                            <th>Backup ID</th>
                                                            <th>Integridade (SHA1)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>{backup.database}</td>
                                                            <td>{backup.updatedAt ? convertFirebaseTimestampToString(backup.updatedAt, i18n().dateFormat) : "-"}</td>
                                                            <td>{backup.id}</td>
                                                            <td>{backup.integrity || "-"}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                {backup.status === "error" && <>
                                                    <Separator size={10} />

                                                    <button
                                                        disabled={loadingTryBackupAgain}
                                                        onClick={() => _retryBackup(backup)}
                                                        type="button"
                                                        className="btn btn-outline-secondary btn-sm">
                                                            <Loading loading={loadingTryBackupAgain} parent="inline" color="text-primary" />
                                                            {!loadingTryBackupAgain && <i className="fas fa-sync me-2"></i>} Tentar Novamente
                                                    </button>
                                                </>}
                                            </Expander>

                                        </div>
                                    </div>

                                    <Separator size={10} />
                                </div>
                            ))}
                        </div>
                    </div>
                </>}
            </div>
        </div>
    );
};

export default Backups;