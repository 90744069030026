import { Separator } from "@moreirapontocom/npmhelpers";
import Stats from "../../components/Stats/Stats.component";
import Logs from "../../components/Logs/Logs.component";
import PageHeader from "../../components/PageHeader/PageHeader.component";

const Dashboard = () => {
    return (
        <div>
            <PageHeader>
                <h1>Dashboard</h1>
                <p>
                    Logs de registros de backups e estatísticas.
                </p>
            </PageHeader>

            <div className="bg-light" style={{ marginTop: "-40px" }}>
                <div className="container pt-4 pb-4">
                    <Stats />
                </div>
            </div>

            <div className="container">
                <Separator size={20} />

                <Logs />
            </div>
        </div>
    );
};

export default Dashboard;