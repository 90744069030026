import { Outlet, useNavigate } from 'react-router-dom';
import { GoogleAuthProvider } from "firebase/auth";
import { UserContext } from '../../context/User.context';
import { useContext, useEffect, useState } from 'react';
import { DataContext } from '../../context/Data.context';
import { Alert, AlertContext, Confirm, ConfirmContext } from '@moreirapontocom/npmhelpers';
import Navbar from '../../components/Navbar/Navbar.component';
import Footer from '../../components/Footer/Footer.component';

const provider = new GoogleAuthProvider();
provider.addScope('https://www.googleapis.com/auth/contacts.readonly');

function App() {
  const appVersion: string = process.env.REACT_APP_VERSION || '0.0.0';
  const { user, setUser } = useContext(UserContext);
  const { setData } = useContext(DataContext);
  const { alert } = useContext(AlertContext);
  const { confirm } = useContext(ConfirmContext);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    _loadData();
  }, [isLoggedIn]);

  const _loadData = async () => {
    const dataStorage: any = localStorage.getItem("data");
    const userStorage: any = localStorage.getItem("user");
    if (dataStorage && userStorage) {
      setData(JSON.parse(dataStorage));
      setUser(JSON.parse(userStorage));
      setIsLoggedIn(true);
      return;
    }
    navigate("/login");
  };

  return <>
    <Alert alert={alert} />
    <Confirm confirm={confirm} />

    <Navbar />

    {user.uid !== "" && <>
      <Outlet />

      <Footer />
    </>}
  </>
}

export default App;
