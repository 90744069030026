import { Separator } from "@moreirapontocom/npmhelpers";
import './PageHeader.component.scss';

const PageHeader = (props: any) => {
    return <>
        <div className="PageHeader bg-light pt-3">
            <div className="container">
                {props.children}
            </div>

            <Separator size={20} />
        </div>

        <Separator size={40} />
    </>;
};

export default PageHeader;