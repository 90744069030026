import { useContext, useState } from "react";
import PageHeader from "../../components/PageHeader/PageHeader.component";
import { Separator, AlertContext } from "@moreirapontocom/npmhelpers";
import { DataContext } from "../../context/Data.context";
import {
    cancelSubscription,
    createSubscription,
} from "../../services/subscription.service";
import { UserContext } from "../../context/User.context";
import { useFormik } from "formik";
import Loading from "../../components/Loading/Loading.component";
import { getAddressFromZipcode } from "../../services/external.service";
import * as yup from "yup";
import { isCPFValid } from "../../helpers";

const GatewayWarning = () => {
    return <>
        <div className="bg-light">
            <small>
                <i className="fas fa-info-circle me-2"></i> <strong>Fique Tranquilo!</strong>
                <Separator size={10} />
                Nós não armazenamos nenhuma informação do seu cartão de crédito. Seu pagamento será processado de forma 100% segura através da plataforma <strong><a href="https://pagar.me/" target="_blank" rel="noopener noreferrer">Pagar.me</a></strong>, uma das maiores plataformas brasileiras de pagamento online.<br />
            </small>
        </div>
    </>
}

const Benefits = (props: any) => {
    const {align, priceDisplay} = props;
    return <>
        <div className={align}>
            <h3 className="mb-0"><strong>{priceDisplay}</strong></h3>
            <small className="text-muted">por mês</small>

            <Separator size={10} />

            <ul className="list-unstyled">
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    7 dias grátis
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Schemas ilimitados
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Backups ilimitados
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Envio automático para o seu Dropbox
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Agendamento de backup automático
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <><i className="fas fa-check-circle text-success me-2"></i></>}
                    {align === "text-end" && <><small className="text-muted">*</small></>}
                    30 dias de retenção dos backups
                    {align === "text-start" && <><small className="text-muted">*</small></>}
                    {align === "text-end" && <><i className="fas fa-check-circle text-success ms-2"></i></>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Suporte por email e WhatsApp
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Cancele quando quiser, sem multa
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Backup protegido com senha <small className="text-muted">(em breve)</small>
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
                <li>
                    {align === "text-start" && <i className="fas fa-check-circle text-success me-2"></i>}
                    Suporte a múltiplos bancos de dados <small className="text-muted">(em breve)</small>
                    {align === "text-end" && <i className="fas fa-check-circle text-success ms-2"></i>}
                </li>
            </ul>

            <Separator size={10} />

            <small className="text-muted">
                * Retenção de 30 dias em nossa infraestrutura de segurança.<br />
                A retenção no seu Dropbox é ilimitada.
            </small>
        </div>
    </>
};

const Subscription = () => {
    const {user} = useContext(UserContext);
    const {setAlert} = useContext(AlertContext);
    const {data, setData} = useContext(DataContext);

    const priceDisplay: string = `R$ ${process.env.REACT_APP_PRICE_DISPLAY || ""}`;

    const defaultSubscriptionFormData: any = {
        customer_phones_mobile_phone_country_code: "55",
        customer_phones_mobile_phone_area_code: "",
        customer_phones_mobile_phone_number: "",
        customer_document: "", // CPF
        customer_name: user.name || "",
        card_number: "",
        card_holder_name: "",
        card_expiration_month: "",
        card_expiration_year: "",
        card_cvv: "",
        customer_address_zip_code: "",
        customer_address_line_1: "",
        customer_address_line_2: "",
        customer_address_city: "",
        customer_address_state: "",
        customer_address_country: "BR",
    };

    const [loadingCreatingSubscription, setLoadingCreatingSubscription] = useState(false);

    const formikSubscription = useFormik({
        initialValues: defaultSubscriptionFormData,
        isInitialValid: false,
        validationSchema: yup.object({
            customer_phones_mobile_phone_country_code: yup.string().required().matches(/^[0-9]+$/, "Somente números").length(2),
            customer_phones_mobile_phone_area_code: yup.string().required().matches(/^[0-9]+$/, "Somente números").length(2),
            customer_phones_mobile_phone_number: yup.string().required().matches(/^[0-9]+$/, "Somente números").length(9),
            customer_document: yup.string().required().length(11).matches(/^[0-9]+$/, "Somente números").test("is-valid-cpf", "CPF inválido", (value: string) => isCPFValid(value)),
            customer_name: yup.string().required(),
            card_number: yup.string().required().matches(/^[0-9]+$/, "Somente números"),
            card_holder_name: yup.string().required(),
            card_expiration_month: yup.string().required(),
            card_expiration_year: yup.string().required(),
            card_cvv: yup.string().required(),
            customer_address_zip_code: yup.string().required().matches(/^[0-9]+$/, "Somente números"),
            customer_address_line_1: yup.string().required(),
            customer_address_line_2: yup.string().required(),
            customer_address_city: yup.string().required(),
            customer_address_state: yup.string().required(),
            customer_address_country: yup.string().required().length(2),
        }),
        onSubmit: async (values: any) => {
            setLoadingCreatingSubscription(true);
            await createSubscription(values).then((response: any) => {
                if (response.message === "OK") {
                    setAlert({type: "success", message: "Assinatura concluída com sucesso!"});
                    const responseData: any = {
                        ...data,
                        subscriptionPlan: response.subscriptionPlan,
                    };
                    setData(responseData);
                    localStorage.setItem("data", JSON.stringify(responseData));
                    setLoadingCreatingSubscription(false);
                    return;
                }
                console.log("Error creating subscription", response);
                setAlert({type: "danger", message: "Erro ao assinar. Verifique os dados e tente novamente."});
                setLoadingCreatingSubscription(false);
            }).catch((error: any) => {
                console.log("ERROR", error);
                setLoadingCreatingSubscription(false);
                setAlert({type: "danger", message: "Erro ao assinar. Verifique os dados e tente novamente."});
            });
        },
    });

    const [loadingCancelSubscription, setLoadingCancelSubscription] = useState(false);

    const _cancelSubscription = async () => {
        setLoadingCancelSubscription(true);
        await cancelSubscription().then((response: any) => {
            if (response.message === "OK") {
                setLoadingCancelSubscription(false);
                setAlert({type: "success", message: "Assinatura cancelada com sucesso!"});
                setData({...data, subscriptionPlan: "free"});
                localStorage.setItem("data", JSON.stringify({...data, subscriptionPlan: "free"}));
                return;
            }
            setLoadingCancelSubscription(false);
            setAlert({type: "danger", message: "Erro ao cancelar a assinatura. "});
        }).catch((error: any) => {
            console.log("ERROR", error);
            setLoadingCancelSubscription(false);
            setAlert({type: "danger", message: "Erro ao cancelar a assinatura. "});
        });
    };

    const [loadingAddressFromZipcode, setLoadingAddressFromZipcode] = useState(false);

    const _getAddressFromZipcode = async (zipcode: string) => {
        if (zipcode.length !== 8) return;
        setLoadingAddressFromZipcode(true);
        await getAddressFromZipcode(zipcode).then((response: any) => {
            if (response.erro && response.erro === true) {
                setLoadingAddressFromZipcode(false);
                return true
            };

            formikSubscription.setFieldValue("customer_address_line_1", response.logradouro);
            formikSubscription.setFieldValue("customer_address_city", response.localidade);
            formikSubscription.setFieldValue("customer_address_state", response.uf);
            if (formikSubscription.values.customer_phones_mobile_phone_area_code === "") {
                formikSubscription.setFieldValue("customer_phones_mobile_phone_area_code", response.ddd);
            }
            setLoadingAddressFromZipcode(false);
        }).catch((error: any) => {
            setAlert({type: "danger", message: "Erro ao obter o endereço. Verifique o CEP e tente novamente."});
            console.log("Error getting address from zipcode", error);
            setLoadingAddressFromZipcode(false);
        });
    };

    return <>
        <PageHeader>
            <h1>Assinatura</h1>
            <p>
                Gerencie a sua assinatura <strong>PRO</strong>.
            </p>
        </PageHeader>

        <div className="container" data-testid="subscription">
            {data.subscriptionPlan === "free" && <>
                <form
                    onSubmit={(e: any) => {
                        e.preventDefault();
                        formikSubscription.submitForm();
                    }}>

                    <div className="row">
                        <div className="col-12 col-md-4">

                            <Separator size={18} />

                            <div className="text-end text-primary">
                                <strong>Assine agora mesmo &rarr;</strong>
                                <div style={{ paddingRight: "19px" }}>
                                    para utilizar todos os recursos<br />
                                    disponíveis na plataforma
                                </div>
                            </div>

                            <Separator size={50} />

                            <Benefits priceDisplay={priceDisplay} align="text-end" />

                        </div>
                        <div className="col-12 col-md-8">

                            <div className="card shadow-sm">
                                <div className="card-body">

                                    <div className="row">
                                        <div className="col-12 col-md-6">



                                            <strong>1. Dados Pessoais</strong>

                                            <Separator size={20} />

                                            <label>Telefone</label>

                                            <div className="row">
                                                <div className="col-4 col-md-3">

                                                    <div className="form-group mb-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="ddd"
                                                            maxLength={2}
                                                            autoComplete="off"
                                                            disabled={loadingCreatingSubscription}
                                                            name="customer_phones_mobile_phone_area_code"
                                                            value={formikSubscription.values.customer_phones_mobile_phone_area_code}
                                                            onChange={formikSubscription.handleChange} />
                                                    </div>

                                                </div>
                                                <div className="col-8 col-md-4">

                                                    <div className="form-group mb-2">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={9}
                                                            autoComplete="off"
                                                            placeholder="Número"
                                                            disabled={loadingCreatingSubscription}
                                                            name="customer_phones_mobile_phone_number"
                                                            value={formikSubscription.values.customer_phones_mobile_phone_number}
                                                            onChange={formikSubscription.handleChange} />
                                                    </div>

                                                </div>
                                            </div>


                                            <div className="row">
                                                <div className="col-12 col-md-7">

                                                    <div className="form-group mb-2">
                                                        <label>Seu CPF </label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            maxLength={11}
                                                            autoComplete="off"
                                                            placeholder="(somente números)"
                                                            disabled={loadingCreatingSubscription}
                                                            name="customer_document"
                                                            value={formikSubscription.values.customer_document}
                                                            onChange={formikSubscription.handleChange} />
                                                    </div>

                                                </div>
                                            </div>


                                            <Separator size={20} />

                                            <strong>2. Endereço</strong>

                                            <Separator size={20} />

                                            <div className="row">
                                                <div className="col-12 col-md-7">
                                                    <div className="form-group mb-2">
                                                        <label>CEP</label>

                                                        {loadingAddressFromZipcode && <><span className="ms-2"><Loading loading={true} parent="inline" color="text-primary" /></span></>}

                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            disabled={loadingCreatingSubscription || loadingAddressFromZipcode}
                                                            name="customer_address_zip_code"
                                                            placeholder="(somente números)"
                                                            maxLength={8}
                                                            autoComplete="off"
                                                            value={formikSubscription.values.customer_address_zip_code}
                                                            onChange={(e: any) => {
                                                                formikSubscription.handleChange(e);
                                                                _getAddressFromZipcode(e.target.value);
                                                            }} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-group mb-2">
                                                <label>Endereço</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={loadingCreatingSubscription || loadingAddressFromZipcode}
                                                    name="customer_address_line_1"
                                                    placeholder="ex: Rua das Flores, 26"
                                                    value={formikSubscription.values.customer_address_line_1}
                                                    onChange={formikSubscription.handleChange} />
                                            </div>

                                            <div className="form-group mb-2">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={loadingCreatingSubscription || loadingAddressFromZipcode}
                                                    name="customer_address_line_2"
                                                    placeholder="Apartamento, Bairro, Região, etc."
                                                    value={formikSubscription.values.customer_address_line_2}
                                                    onChange={formikSubscription.handleChange} />
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-md-7">

                                                    <div className="form-group mb-2">
                                                        <label>Cidade</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled={loadingCreatingSubscription || loadingAddressFromZipcode}
                                                            name="customer_address_city"
                                                            placeholder="ex: São Paulo"
                                                            maxLength={8}
                                                            value={formikSubscription.values.customer_address_city}
                                                            onChange={formikSubscription.handleChange} />
                                                    </div>

                                                </div>
                                                <div className="col-12 col-md-5">

                                                    <div className="form-group mb-2">
                                                        <label>Estado</label>
                                                        <select
                                                            className="form-control"
                                                            disabled={loadingCreatingSubscription || loadingAddressFromZipcode}
                                                            name="customer_address_state"
                                                            value={formikSubscription.values.customer_address_state}
                                                            onChange={formikSubscription.handleChange}>
                                                                <option value="">-</option>
                                                                <option value="AC">Acre</option>
                                                                <option value="AL">Alagoas</option>
                                                                <option value="AP">Amapá</option>
                                                                <option value="AM">Amazonas</option>
                                                                <option value="BA">Bahia</option>
                                                                <option value="CE">Ceará</option>
                                                                <option value="DF">Distrito Federal</option>
                                                                <option value="ES">Espirito Santo</option>
                                                                <option value="GO">Goiás</option>
                                                                <option value="MA">Maranhão</option>
                                                                <option value="MS">Mato Grosso do Sul</option>
                                                                <option value="MT">Mato Grosso</option>
                                                                <option value="MG">Minas Gerais</option>
                                                                <option value="PA">Pará</option>
                                                                <option value="PB">Paraíba</option>
                                                                <option value="PR">Paraná</option>
                                                                <option value="PE">Pernambuco</option>
                                                                <option value="PI">Piauí</option>
                                                                <option value="RJ">Rio de Janeiro</option>
                                                                <option value="RN">Rio Grande do Norte</option>
                                                                <option value="RS">Rio Grande do Sul</option>
                                                                <option value="RO">Rondônia</option>
                                                                <option value="RR">Roraima</option>
                                                                <option value="SC">Santa Catarina</option>
                                                                <option value="SP">São Paulo</option>
                                                                <option value="SE">Sergipe</option>
                                                                <option value="TO">Tocantis</option>
                                                        </select>
                                                    </div>

                                                </div>
                                            </div>

                                            <Separator size={20} />

                                            <small className="text-muted">
                                                <strong><i className="fas fa-info-circle me-2"></i> Por que precisamos destas informações?</strong><br />
                                                Estas informações são necessárias para a emissão da sua Nota Fiscal e para o sistema de cobrança automática da Pagar.me, a plataforma de pagamento que utilizamos.
                                            </small>



                                        </div>
                                        <div className="col-12 col-md-6">


                                            <strong>3. Dados do Cartão</strong>

                                            <Separator size={20} />

                                            <div className="form-group mb-2">
                                                <label>Nome Impresso no Cartão</label>
                                                <input
                                                    type="text"
                                                    placeholder="Nome impresso no cartão"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    disabled={loadingCreatingSubscription}
                                                    name="card_holder_name"
                                                    value={formikSubscription.values.card_holder_name}
                                                    onChange={formikSubscription.handleChange} />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label>Número do Cartão</label>
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    autoComplete="off"
                                                    placeholder="(somento números)"
                                                    disabled={loadingCreatingSubscription}
                                                    name="card_number"
                                                    value={formikSubscription.values.card_number}
                                                    onChange={formikSubscription.handleChange} />
                                            </div>

                                            <small className="text-muted">Validade</small>

                                            <div className="row">
                                                <div className="col-12 col-md-5">
                                                    <div className="form-group mb-2">
                                                        <label>Mês</label>
                                                        <select
                                                            className="form-control"
                                                            disabled={loadingCreatingSubscription}
                                                            name="card_expiration_month"
                                                            value={formikSubscription.values.card_expiration_month}
                                                            onChange={formikSubscription.handleChange}>
                                                                <option value="">-</option>
                                                                <option value="1">01 - Janeiro</option>
                                                                <option value="2">02 - Fevereiro</option>
                                                                <option value="3">03 - Março</option>
                                                                <option value="4">04 - Abril</option>
                                                                <option value="5">05 - Maio</option>
                                                                <option value="6">06 - Junho</option>
                                                                <option value="7">07 - Julho</option>
                                                                <option value="8">08 - Agosto</option>
                                                                <option value="9">09 - Setembro</option>
                                                                <option value="10">10 - Outubro</option>
                                                                <option value="11">11 - Novembro</option>
                                                                <option value="12">12 - Dezembro</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <div className="form-group mb-2">
                                                        <label>Ano</label>
                                                        <select
                                                            className="form-control"
                                                            disabled={loadingCreatingSubscription}
                                                            name="card_expiration_year"
                                                            value={formikSubscription.values.card_expiration_year}
                                                            onChange={formikSubscription.handleChange}>
                                                                <option value="">-</option>
                                                                <option value="2023">2023</option>
                                                                <option value="2024">2024</option>
                                                                <option value="2025">2025</option>
                                                                <option value="2026">2026</option>
                                                                <option value="2027">2027</option>
                                                                <option value="2028">2028</option>
                                                                <option value="2029">2029</option>
                                                                <option value="2030">2030</option>
                                                                <option value="2031">2031</option>
                                                                <option value="2032">2032</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-12 col-md-4">
                                                    <div className="form-group mb-2">
                                                        <label>CVV</label>
                                                        <input
                                                            type="password"
                                                            className="form-control"
                                                            autoComplete="off"
                                                            disabled={loadingCreatingSubscription}
                                                            name="card_cvv"
                                                            value={formikSubscription.values.card_cvv}
                                                            onChange={formikSubscription.handleChange} />
                                                    </div>
                                                </div>
                                            </div>

                                            <Separator size={20} />

                                            <GatewayWarning />

                                            <Separator size={10} />

                                            <button
                                                type="submit"
                                                disabled={loadingCreatingSubscription || loadingAddressFromZipcode || !formikSubscription.isValid}
                                                className="btn btn-success">
                                                    <Loading loading={loadingCreatingSubscription} parent="inline" color="text-white" />
                                                    {!loadingCreatingSubscription && <i className="fas fa-check-circle me-2"></i>} Confirmar Assinatura
                                            </button>

                                            <Separator size={20} />


                                            <small className="text-muted">
                                                Ao confirmar a assinatura, você concorda com os nossos <a href="/terms" target="_blank" rel="noopener noreferrer">Termos de Uso</a> e nos autoriza a cobrar seu cartão de crédito o valor de {priceDisplay} por mês. Sua assinatura continuará até ser cancelada. Você pode cancelar a qualquer momento, sem multa.
                                            </small>


                                        </div>
                                    </div>

                                </div>{/* /.card-body */}
                            </div>{/* /.card */}

                        </div>
                    </div>
                </form>
            </>}


            {data.subscriptionPlan === "pro" && <>

                <div className="row">
                    <div className="col-12 col-md-6">

                        <Separator size={5} />

                        <div className="text-primary text-end">
                            <strong>Estes são os benefícios &rarr; </strong>
                            <div style={{ paddingRight: "19px" }}>
                                da sua assinatura
                            </div>
                        </div>

                        <Separator size={30} />

                        <div className="alert alert-primary">
                            <i className="fas fa-check-circle me-2"></i> Sua assinatura está <strong>ATIVA</strong>
                        </div>

                        <Separator size={20} />

                        <GatewayWarning />

                        <Separator size={10} />

                        <div className="text-end">
                            <button
                                type="button"
                                disabled={loadingCancelSubscription}
                                onClick={() => _cancelSubscription()}
                                className="btn btn-link inline btn-sm text-secondary">
                                <small>
                                    <Loading loading={loadingCancelSubscription} parent="inline" color="text-secondary" />
                                    {!loadingCancelSubscription && <i className="fas fa-times me-2"></i>} Cancelar Assinatura
                                </small>
                            </button>
                        </div>

                    </div>
                    <div className="col-12 col-md-6">

                        <Benefits priceDisplay={priceDisplay} align="text-start" />

                    </div>
                </div>

                
            </>}
        </div>
    </>;
};

export default Subscription;